<template>
  <div class="per_person" @mouseout="hoverMenu = ''">
    <h2>{{ $t('msg.PERS010L020.021') }}</h2>
    <ul class="part_menu">
      <li v-for="manager in managerList" :key="'menu_per_person_' + manager.type">
        <a
          href="javascript:void(0)"
          :class="activeMenu == manager.type || hoverMenu == manager.type ? 'on' : ''"
          @click="clickMenu(manager.type)"
          @mouseover="mouseover(manager.type)"
        >{{ manager.name }}
        </a>
      </li>
    </ul>
    <template v-for="manager in managerList">
      <template v-if="manager.type == activeMenu">
        <template v-for="(mInfo, index) in manager.info">
          <div class="info" :key="'info_per_person_' + manager.type + '_' + mInfo.name + '_' + index">
            <!-- <dl v-if="memberDetail.userCtrCd === mInfo.ctrCd || mInfo.ctrCd === undefined"> -->
            <dl>
              <dt>{{ mInfo.name }}<template v-if="!isEmpty(mInfo.desc)">({{ mInfo.desc }})</template> </dt>
              <dd>{{ mInfo.eml }}</dd>
              <dd class="tel">{{ mInfo.tel }}</dd>
              <dd class="tel" v-if="!isEmpty(mInfo.phone)">{{ mInfo.phone }}</dd>
            </dl>
          </div>
        </template>
      </template>
    </template>
  </div>
</template>

<script>
import ManagerInfoApi from '@/api/rest/user/manager'
import { rootComputed } from '@/store/helpers'
import managerInfo from '@/plugins/managerInfo.json'
export default {
  name: 'QuickManagerInfo',
  components: {

  },
  props: [
    'countryDigitCode',
    'isLogin'
  ],
  data: function () {
    return {
        activeMenu: 'sales',
        hoverMenu: '',
        managerList: [
          {
            name: 'Sales',
            type: 'sales',
            info: []
          },
          {
            name: 'CS (Outbound)',
            type: 'outbound',
            info: []
          },
          {
            name: 'Docu',
            type: 'documentation',
            info: []
          },
          {
            name: 'CS (Inbound)',
            type: 'inbound',
            info: []
          }
        ],
        prdYn: process.env.VUE_APP_MODE !== 'PRD'
    }
  },
  computed: {
    ...rootComputed
  },
  watch: {},
  created () {},
  mounted: function () {
    // this.getManagerInfo()
  },
  methods: {
    async getManagerInfo () {
      const isLoginTxt = this.isLogin ? 'login' : 'noLogin'
      //this.managerList[1].info = managerInfo[isLoginTxt].outbound[this.countryDigitCode]

      if (this.isLogin) {
        this.managerList[2].info = managerInfo[isLoginTxt].documentation[this.countryDigitCode]
        this.managerList[3].info = managerInfo[isLoginTxt].inbound[this.countryDigitCode]

        await ManagerInfoApi.getManagerInfo().then(response => {
          // Sales
          for (let info of response.data.salesList) {
            if (this.countryDigitCode === 'KR') {
              if (this.isEmpty(info.city)) {
                info.desc = null
              } else if (info.city === 'SEL') {
                if (this.isEmpty(info.kind)) {
                  info.desc = null
                } else if (info.kind === 'O') {
                  info.desc = 'Export'
                } else if (info.kind === 'I') {
                  info.desc = 'Import'
                } else if (info.kind === 'A' || info.gubun === 'JAPAN') {
                  info.desc = 'Japan'
                }
              } else {
                if (info.city === 'PUS') {
                  info.desc = 'Busan'
                } else if (info.city === 'USN') {
                  info.desc = 'Ulsan'
                } else if (info.city === 'INC') {
                  info.desc = 'Incheon'
                } else {
                  info.desc = null
                }
              }
            } else {
              if (this.isEmpty(info.kind)) {
                  info.desc = null
                } else if (info.kind === 'O') {
                  info.desc = 'Export'
                } else if (info.kind === 'I') {
                  info.desc = 'Import'
                } else {
                  info.desc = null
                }
            }
          }
          this.managerList[0].info = response.data.salesList

          if (this.countryDigitCode === 'KR') {
            ManagerInfoApi.getSpecialContainerManagerInfo().then(response => {
              console.log('getSpecialContainerManagerInfo', response)
              for (let info of response.data.salesList) {
                info.name = info.name + '(' + info.gubun + ')'
                this.managerList[0].info.push(info)
              }
            }).catch(e => {
              console.log(e)
            })
          } else {
            for (let info of managerInfo[isLoginTxt].sales[this.countryDigitCode]) {
              this.managerList[0].info.push(info)
            }
          }

          /* for (let info of managerInfo[isLoginTxt].sales[this.countryDigitCode]) {
            this.managerList[0].info.push(info)
          } */

          // CS(Outbound)
          const csOutList = response.data.csOutList
          const newCsOutList = response.data.newCsOutList
          let newCsOutArr = []
          const TH = this

          this.managerList[1].info = csOutList

          if (this.memberDetail.userCtrCd === 'KR') {
            newCsOutList.forEach(ele => {
              let rmk = []
              let eml
              let tel
              let name
              rmk = ele.rmk.replaceAll('\r', '').split('\n')

              rmk.forEach(rmkEle => {
                if (rmkEle.indexOf('@') > -1) {
                  eml = rmkEle.replaceAll('E-mail. :', '') || ''
                }

                if (rmkEle.indexOf('-') > -1) {
                  tel = rmkEle || ''
                }
              })

              name = ele.strSntcCont

              newCsOutArr.push({
                eml, tel, name
              })
            })

            this.managerList[1].info = [...csOutList, ...newCsOutArr]
          }

          // CRM 에 입력한 담당자가 없을때만 디폴트 담당자 조회 -> 원복(2022/05/12)
          // if (this.managerList[1].info.length === 0) {
          for (let csOutInfo of managerInfo[isLoginTxt].outbound[this.countryDigitCode]) {
            this.managerList[1].info.push(csOutInfo)
          }
          // }
        }).catch(e => {
          console.log(e)
        })
      } else {
        this.managerList[0].info = managerInfo[isLoginTxt].sales[this.countryDigitCode]
        this.managerList[1].info = managerInfo[isLoginTxt].outbound[this.countryDigitCode]
        this.managerList[2].info = managerInfo[isLoginTxt].documentation[this.countryDigitCode]
        this.managerList[3].info = managerInfo[isLoginTxt].inbound[this.countryDigitCode]

        if (this.countryDigitCode === 'KR') {
          ManagerInfoApi.getSpecialContainerManagerInfo().then(response => {
            console.log('getSpecialContainerManagerInfo', response)
            for (let info of response.data.salesList) {
              info.name = info.gubun
              this.managerList[0].info.push(info)
            }
          }).catch(e => {
            console.log(e)
          })
        }
      }
    },
    clickMenu (type) {
      this.activeMenu = type
    },
    mouseover (type) {
      this.hoverMenu = type
    },
    isEmpty (value) {
      if (value === '' || value === null || value === undefined || (value != null && typeof value === 'object' && !Object.keys(value).length)) {
          return true
      } else {
          return false
      }
    }
  }
}
</script>
