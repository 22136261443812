<template>
  <div class="rate-management">
    <div class="page-header">
      <h1>Rate Management</h1>
    </div>

    <div class="search-area">
      <div class="search-row">
        <div class="search-item">
          <label>Effective Date</label>
          <date-range-picker v-model="searchParams.effectiveDate" />
          <e-date-range-picker
            :id="'fromDtToDt'"
            term-type="02"
            :show-term-type="['01', '02', '03', '04']"
            :sdate="searchParams.effectiveDate.start"
            :edate="searchParams.effectiveDate.end"
          />
        </div>
        <div class="search-item">
          <label>POR</label>
          <port-search-component v-model="searchParams.por" />
        </div>
        <div class="search-item">
          <label>DLY</label>
          <port-search-component v-model="searchParams.dly" />
        </div>
        <div class="search-item">
          <label>P/C</label>
          <select v-model="searchParams.frtPncCd" placeholder="ALL">
            <option label="ALL" value="" />
            <option label="Prepaid" value="P" />
            <option label="Collect" value="C" />
          </select>
        </div>
        <div class="search-item">
          <label>Hot Deal</label>
          <select v-model="searchParams.hotDealYn" placeholder="">
            <option label="" value="" />
            <option label="Y" value="Y" />
            <option label="N" value="N" />
          </select>
        </div>
      </div>

      <div class="search-row">
        <div class="search-item">
          <label>Input by</label>
          <div class="input-group">
            <select v-model="searchParams.inputType" placeholder="ALL">
              <option label="ALL" value="" />
              <option label="ICC" value="I" />
              <option label="WEB" value="W" />
            </select>
            <el-input
              v-model="searchParams.inputBy"
              placeholder="Input By"
              :disabled="!searchParams.inputType"
              @input="handleInputByChange"
            />
          </div>
        </div>
        <div class="search-item">
          <label>Status</label>
          <select v-model="searchParams.stsCd" placeholder="">
            <option label="" value="" />
            <option label="Request" value="02" />
            <option label="Accept" value="03" />
            <option label="Reject" value="04" />
          </select>
        </div>
        <div class="search-item">
          <label>CNTR Type</label>
          <select v-model="searchParams.cntrTypCd" placeholder="">
            <option label="" value="" />
            <option label="GP" value="GP" />
            <option label="RF" value="RF" />
            <option label="FR" value="FR" />
            <option label="OT" value="OT" />
          </select>
        </div>
        <div class="search-item search-buttons">
          <el-button type="primary" @click="search">Search</el-button>
          <el-button @click="resetSearch">Reset</el-button>
        </div>
      </div>
    </div>

    <div class="grid-actions">
      <div class="grid-action-left">
        <el-button type="primary" @click="handleNew">New</el-button>
        <el-button type="primary" @click="handleSave">Save</el-button>
      </div>
      <div class="grid-action-right">
        <el-button @click="handleApprove">Approve</el-button>
        <el-button @click="handleReject">Reject</el-button>
        <el-button @click="handleExpire">Expire</el-button>
        <el-button @click="handleUpdate">Update</el-button>
      </div>
    </div>

    <div class="grid-tools">
      <div class="row-tools">
        <span>Row (Max : 100)</span>
        <el-input
          v-model="addRowCount"
          type="number"
          placeholder="Row count"
          style="width: 100px"
        />
        <el-button size="small" @click="addRows">Add Row</el-button>
        <el-button size="small" @click="deleteRows">Del</el-button>
      </div>
    </div>

    <div class="grid-container">
      <div ref="realGrid" style="width: 100%; height: 500px;"></div>
    </div>
  </div>
</template>

<script>
import { GridView, LocalDataProvider } from 'realgrid'
/*import DateRangePicker from '@/components/common/DateRangePicker.vue'
import PortSearchComponent from '@/components/common/PortSearchComponent.vue'*/

export default {
  name: 'RateManagement',
  components: {
    /*DateRangePicker,
    PortSearchComponent*/
  },
  data () {
    return {
      searchParams: {
        effectiveDate: {
          start: null,
          end: null
        },
        por: null,
        dly: null,
        frtPncCd: '',
        hotDealYn: '',
        inputType: '',
        inputBy: '',
        stsCd: '',
        cntrTypCd: ''
      },
      addRowCount: 1,
      gridView: null,
      dataProvider: null,
      gridData: []
    }
  },
  mounted () {
    this.initializeGrid()
  },
  beforeDestroy () {
    // 그리드 리소스 해제
    if (this.gridView) {
      this.gridView.destroy()
    }
    if (this.dataProvider) {
      this.dataProvider.destroy()
    }
  },
  methods: {
    initializeGrid () {
      this.dataProvider = new LocalDataProvider()
      this.gridView = new GridView(this.$refs.realGrid)
      this.gridView.setDataSource(this.dataProvider)

      // 필드 정의
      this.dataProvider.setFields([
        { fieldName: 'chk', dataType: 'boolean' },
        { fieldName: 'rteCdYn', dataType: 'text' },
        { fieldName: 'vslCdYn', dataType: 'text' },
        { fieldName: 'voyNoYn', dataType: 'text' },
        { fieldName: 'porCtrCd', dataType: 'text' },
        { fieldName: 'porGrpCd', dataType: 'text' },
        { fieldName: 'porPlcCd', dataType: 'text' },
        { fieldName: 'dlyCtrCd', dataType: 'text' },
        { fieldName: 'dlyGrpCd', dataType: 'text' },
        { fieldName: 'dlyPlcCd', dataType: 'text' },
        { fieldName: 'frtPncCd', dataType: 'text' },
        { fieldName: 'cntrTypCd', dataType: 'text' },
        { fieldName: 'stsCd', dataType: 'text' },
        { fieldName: 'hotDealYn', dataType: 'text' },
        { fieldName: 'lstUpdDtm', dataType: 'text' },
        { fieldName: 'reqUno', dataType: 'text' },
        { fieldName: 'strDt', dataType: 'text' },
        { fieldName: 'expDt', dataType: 'text' },
        { fieldName: 'lstExpyUno', dataType: 'text' },
        { fieldName: 'lstExpyDtm', dataType: 'text' },
        { fieldName: 'of20Amt', dataType: 'number' },
        { fieldName: 'of40Amt', dataType: 'number' },
        { fieldName: 'ofDg20Amt', dataType: 'number' },
        { fieldName: 'ofDg40Amt', dataType: 'number' },
        { fieldName: 'ohOog20Amt', dataType: 'number' },
        { fieldName: 'ohOog40Amt', dataType: 'number' },
        { fieldName: 'owOog20Amt', dataType: 'number' },
        { fieldName: 'owOog40Amt', dataType: 'number' },
        { fieldName: 'owhOog20Amt', dataType: 'number' },
        { fieldName: 'owhOog40Amt', dataType: 'number' },
        { fieldName: 'lclLdList', dataType: 'text' },
        { fieldName: 'lclDisList', dataType: 'text' },
        { fieldName: 'surLdList', dataType: 'text' },
        { fieldName: 'surDisList', dataType: 'text' },
        { fieldName: 'leadDt', dataType: 'text' },
        { fieldName: 'closVslYn', dataType: 'boolean' },
        { fieldName: 'dupYn', dataType: 'text' }
      ])

      // 컬럼 정의
      this.gridView.setColumns([
        { name: 'chk', fieldName: 'chk', type: 'checkbox', width: 35, header: { text: 'CHK' } },
        { name: 'rteCdYn', fieldName: 'rteCdYn', width: 55, header: { text: 'Route' } },
        {
 name: 'vslVoy',
fieldName: 'vslCdYn',
width: 55,
header: { text: 'VSL/VOY' },
          dynamicStyles: [{
            criteria: "value = 'Y'",
            styles: {
              tooltip: {
                visible: true,
                expression: "value == 'Y' ? 'VSL : ' + values['vslCd'] + ' VOY : ' + values['voyNo'] : ''"
              }
            }
          }]
        },
        { name: 'porCtrCd', fieldName: 'porCtrCd', width: 60, header: { text: 'POR CN' }, editable: true },
        { name: 'porGrpCd', fieldName: 'porGrpCd', width: 60, header: { text: 'POR Area' }, editable: true },
        { name: 'porPlcCd', fieldName: 'porPlcCd', width: 60, header: { text: 'POR' }, editable: true },
        { name: 'dlyCtrCd', fieldName: 'dlyCtrCd', width: 60, header: { text: 'DLY CN' }, editable: true },
        { name: 'dlyGrpCd', fieldName: 'dlyGrpCd', width: 60, header: { text: 'DLY Area' }, editable: true },
        { name: 'dlyPlcCd', fieldName: 'dlyPlcCd', width: 60, header: { text: 'DLY' }, editable: true },
        { name: 'frtPncCd', fieldName: 'frtPncCd', width: 40, header: { text: 'P/C' }, editable: true },
        { name: 'cntrTypCd', fieldName: 'cntrTypCd', width: 40, header: { text: 'Type' }, editable: true },
        { name: 'stsCd', fieldName: 'stsCd', width: 80, header: { text: 'Status' } },
        { name: 'hotDealYn', fieldName: 'hotDealYn', width: 45, header: { text: 'Hot\nDeal' }, editable: true },
        { name: 'lstUpdDtm', fieldName: 'lstUpdDtm', width: 80, header: { text: 'Date' } },
        { name: 'reqUno', fieldName: 'reqUno', width: 80, header: { text: 'By' } },
        { name: 'strDt', fieldName: 'strDt', width: 80, header: { text: 'Start' }, editable: true },
        { name: 'expDt', fieldName: 'expDt', width: 80, header: { text: 'Expire' }, editable: true },
        { name: 'lstExpyUno', fieldName: 'lstExpyUno', width: 80, header: { text: 'Expired By' } },
        { name: 'lstExpyDtm', fieldName: 'lstExpyDtm', width: 80, header: { text: 'Expired Date' } },
        { name: 'of20Amt', fieldName: 'of20Amt', width: 70, header: { text: '20\'' }, editable: true, numberFormat: '#,##0' },
        { name: 'of40Amt', fieldName: 'of40Amt', width: 70, header: { text: '40\'' }, editable: true, numberFormat: '#,##0' },
        { name: 'ofDg20Amt', fieldName: 'ofDg20Amt', width: 80, header: { text: '20\' DG ADD' }, editable: true, numberFormat: '#,##0' },
        { name: 'ofDg40Amt', fieldName: 'ofDg40Amt', width: 80, header: { text: '40\' DG ADD' }, editable: true, numberFormat: '#,##0' },
        { name: 'ohOog20Amt', fieldName: 'ohOog20Amt', width: 85, header: { text: '20\' OH ADD' }, editable: true, numberFormat: '#,##0' },
        { name: 'ohOog40Amt', fieldName: 'ohOog40Amt', width: 85, header: { text: '40\' OH ADD' }, editable: true, numberFormat: '#,##0' },
        { name: 'owOog20Amt', fieldName: 'owOog20Amt', width: 85, header: { text: '20\' OW ADD' }, editable: true, numberFormat: '#,##0' },
        { name: 'owOog40Amt', fieldName: 'owOog40Amt', width: 85, header: { text: '40\' OW ADD' }, editable: true, numberFormat: '#,##0' },
        { name: 'owhOog20Amt', fieldName: 'owhOog20Amt', width: 85, header: { text: '20\' OWH ADD' }, editable: true, numberFormat: '#,##0' },
        { name: 'owhOog40Amt', fieldName: 'owhOog40Amt', width: 85, header: { text: '40\' OWH ADD' }, editable: true, numberFormat: '#,##0' },
        { name: 'lclLdList', fieldName: 'lclLdList', width: 80, header: { text: 'Loading Port' } },
        { name: 'lclDisList', fieldName: 'lclDisList', width: 100, header: { text: 'Discharging Port' } },
        { name: 'surLdList', fieldName: 'surLdList', width: 80, header: { text: 'Loading Port' } },
        { name: 'surDisList', fieldName: 'surDisList', width: 100, header: { text: 'Discharging Port' } },
        { name: 'leadDt', fieldName: 'leadDt', width: 50, header: { text: 'Lead\ntime' } },
        { name: 'closVslYn', fieldName: 'closVslYn', width: 130, header: { text: 'WEB BOOKING ALLOW\nFOR CLOSED VESSEL' }, editable: true }
      ])

      // 그리드 스타일 설정
      this.gridView.setStyles({
        body: {
          dynamicStyles: [{
            criteria: "values['dupYn'] = 'Y'",
            styles: { background: 'lightpink' }
          }]
        }
      })

      // 그리드 옵션 설정
      this.gridView.setOptions({
        edit: {
          insertable: true,
          updatable: true,
          deletable: true,
          readOnly: false,
          validateOnEdited: true,
          commitByCell: true
        },
        display: {
          rowHeight: 24,
          headerHeight: 30
        },
        select: {
          style: 'rows',
          multiple: true
        }
      })

      // 초기 데이터 로드
      this.loadGridData()
    },

    loadGridData () {
      // API 호출하여 데이터 로드
      this.fetchRateData()
        .then(response => {
          this.gridData = response.data || []
          this.dataProvider.setRows(this.gridData)
        })
        .catch(error => {
          console.error('Error loading rate data:', error)
          this.$message.error('Failed to load rate data')
        })
    },

    fetchRateData () {
      // 실제 API 호출 구현
      // 예시 코드이므로 실제 구현 시 axios 등을 사용하여 API 호출
      return new Promise((resolve) => {
        // 더미 데이터 반환
        setTimeout(() => {
          resolve({
            data: []
          })
        }, 300)
      })
    },

    search () {
      // 검색 조건을 기반으로 데이터 조회
      console.log('Search with params:', this.searchParams)
      this.loadGridData()
    },

    resetSearch () {
      this.searchParams = {
        effectiveDate: {
          start: null,
          end: null
        },
        por: null,
        dly: null,
        frtPncCd: '',
        hotDealYn: '',
        inputType: '',
        inputBy: '',
        stsCd: '',
        cntrTypCd: ''
      }
    },

    handleNew () {
      // 신규 등록 화면으로 이동 또는 모달 표시
      this.$router.push({ name: 'RateRegistration' })
    },

    handleSave () {
      // 변경된 데이터 저장
      const modifiedRows = this.dataProvider.getStateRows('created').concat(
        this.dataProvider.getStateRows('updated'),
        this.dataProvider.getStateRows('deleted')
      )

      if (modifiedRows.length === 0) {
        this.$message.warning('No changes to save')
        return
      }

      // 변경된 데이터 추출
      const changes = {
        created: this.getRowsData(this.dataProvider.getStateRows('created')),
        updated: this.getRowsData(this.dataProvider.getStateRows('updated')),
        deleted: this.getRowsData(this.dataProvider.getStateRows('deleted'))
      }

      // API 호출하여 저장
      this.saveChanges(changes)
        .then(() => {
          this.$message.success('Changes saved successfully')
          this.loadGridData() // 데이터 리로드
        })
        .catch(error => {
          console.error('Error saving changes:', error)
          this.$message.error('Failed to save changes')
        })
    },

    getRowsData (rowIds) {
      return rowIds.map(id => {
        const row = this.dataProvider.getJsonRow(id)
        return row
      })
    },

    saveChanges (changes) {
      // 실제 API 호출 구현
      console.log('Saving changes:', changes)
      return Promise.resolve()
    },

    handleApprove () {
      const selectedRows = this.gridView.getSelectedRows()
      if (selectedRows.length === 0) {
        this.$message.warning('Please select rows to approve')
        return
      }

      this.$confirm('Are you sure you want to approve the selected rates?', 'Confirm', {
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        type: 'warning'
      }).then(() => {
        // 승인 처리 로직
        const rowsData = this.getRowsData(selectedRows)
        this.approveRates(rowsData)
          .then(() => {
            this.$message.success('Rates approved successfully')
            this.loadGridData()
          })
          .catch(error => {
            console.error('Error approving rates:', error)
            this.$message.error('Failed to approve rates')
          })
      }).catch(() => {
        // 취소 처리
      })
    },

    approveRates (rowsData) {
      // 실제 API 호출 구현
      console.log('Approving rates:', rowsData)
      return Promise.resolve()
    },

    handleReject () {
      const selectedRows = this.gridView.getSelectedRows()
      if (selectedRows.length === 0) {
        this.$message.warning('Please select rows to reject')
        return
      }

      this.$confirm('Are you sure you want to reject the selected rates?', 'Confirm', {
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        type: 'warning'
      }).then(() => {
        // 거부 처리 로직
        const rowsData = this.getRowsData(selectedRows)
        this.rejectRates(rowsData)
          .then(() => {
            this.$message.success('Rates rejected successfully')
            this.loadGridData()
          })
          .catch(error => {
            console.error('Error rejecting rates:', error)
            this.$message.error('Failed to reject rates')
          })
      }).catch(() => {
        // 취소 처리
      })
    },

    rejectRates (rowsData) {
      // 실제 API 호출 구현
      console.log('Rejecting rates:', rowsData)
      return Promise.resolve()
    },

    handleExpire () {
      const selectedRows = this.gridView.getSelectedRows()
      if (selectedRows.length === 0) {
        this.$message.warning('Please select rows to expire')
        return
      }

      this.$confirm('Are you sure you want to expire the selected rates?', 'Confirm', {
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        type: 'warning'
      }).then(() => {
        // 만료 처리 로직
        const rowsData = this.getRowsData(selectedRows)
        this.expireRates(rowsData)
          .then(() => {
            this.$message.success('Rates expired successfully')
            this.loadGridData()
          })
          .catch(error => {
            console.error('Error expiring rates:', error)
            this.$message.error('Failed to expire rates')
          })
      }).catch(() => {
        // 취소 처리
      })
    },

    expireRates (rowsData) {
      // 실제 API 호출 구현
      console.log('Expiring rates:', rowsData)
      return Promise.resolve()
    },

    handleUpdate () {
      const selectedRows = this.gridView.getSelectedRows()
      if (selectedRows.length === 0) {
        this.$message.warning('Please select a row to update')
        return
      }

      if (selectedRows.length > 1) {
        this.$message.warning('Please select only one row to update')
        return
      }

      const rowData = this.getRowsData(selectedRows)[0]
      this.$router.push({
        name: 'RateUpdate',
        params: { id: rowData.id },
        query: { data: JSON.stringify(rowData) }
      })
    },

    addRows () {
      const count = parseInt(this.addRowCount) || 1
      if (count <= 0 || count > 100) {
        this.$message.warning('Please enter a valid row count (1-100)')
        return
      }

      for (let i = 0; i < count; i++) {
        const newRowData = this.createEmptyRow()
        this.dataProvider.addRow(newRowData)
      }
    },

    createEmptyRow () {
      return {
        chk: false,
        rteCdYn: '',
        vslCdYn: '',
        voyNoYn: '',
        porCtrCd: '',
        porGrpCd: '',
        porPlcCd: '',
        dlyCtrCd: '',
        dlyGrpCd: '',
        dlyPlcCd: '',
        frtPncCd: '',
        cntrTypCd: '',
        stsCd: '02', // Request 상태로 기본 설정
        hotDealYn: 'N',
        strDt: this.formatDate(new Date()),
        expDt: '',
        of20Amt: 0,
        of40Amt: 0,
        ofDg20Amt: 0,
        ofDg40Amt: 0,
        ohOog20Amt: 0,
        ohOog40Amt: 0,
        owOog20Amt: 0,
        owOog40Amt: 0,
        owhOog20Amt: 0,
        owhOog40Amt: 0,
        closVslYn: false
      }
    },

    formatDate (date) {
      const year = date.getFullYear()
      const month = String(date.getMonth() + 1).padStart(2, '0')
      const day = String(date.getDate()).padStart(2, '0')
      return `${year}${month}${day}`
    },

    deleteRows () {
      const selectedRows = this.gridView.getSelectedRows()
      if (selectedRows.length === 0) {
        this.$message.warning('Please select rows to delete')
        return
      }

      this.$confirm(`Are you sure you want to delete ${selectedRows.length} selected rows?`, 'Confirm', {
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        type: 'warning'
      }).then(() => {
        this.dataProvider.removeRows(selectedRows)
      }).catch(() => {
        // 취소 처리
      })
    },

    handleInputByChange (value) {
      // 입력자 검색 로직
      if (value && value.length >= 2) {
        // 사용자 검색 API 호출
        this.searchUsers(value)
          .then(users => {
            if (users.length === 1) {
              // 단일 결과인 경우 자동 선택
              this.searchParams.inputBy = users[0].userCode
            }
          })
          .catch(error => {
            console.error('Error searching users:', error)
          })
      }
    },

    searchUsers (keyword) {
      // 실제 API 호출 구현
      console.log('Searching users with keyword:', keyword)
      return Promise.resolve([])
    }
  }
}
</script>

<style scoped>
.rate-management {
  padding: 20px;
  background-color: #fff;
}

.page-header {
  margin-bottom: 20px;
}

.page-header h1 {
  font-size: 24px;
  font-weight: 500;
  color: #333;
  margin: 0;
}

.search-area {
  background-color: #f5f7fa;
  padding: 15px;
  border-radius: 4px;
  margin-bottom: 20px;
}

.search-row {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.search-row:last-child {
  margin-bottom: 0;
}

.search-item {
  display: flex;
  align-items: center;
  margin-right: 20px;
  margin-bottom: 10px;
}

.search-item label {
  width: 100px;
  text-align: right;
  padding-right: 10px;
  font-size: 14px;
  color: #606266;
}

.search-item .input-group {
  display: flex;
}

.search-item .input-group .el-select {
  margin-right: 5px;
}

.search-buttons {
  margin-left: auto;
}

.grid-actions {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.grid-action-left,
.grid-action-right {
  display: flex;
  gap: 10px;
}

.grid-tools {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  align-items: center;
}

.row-tools {
  display: flex;
  align-items: center;
  gap: 10px;
}

.grid-container {
  border: 1px solid #dcdfe6;
  border-radius: 4px;
}
</style>
