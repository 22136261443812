<template>
  <div class="quick_wrap">
    <!-- quick_wrap -->
    <a href="javascript:void(0)" @click="quickOpen()" class="quick_open"><span>open</span></a>
    <div class="quick_box h-100" style="right: -362px;">
      <!-- quick_box -->
      <div class="quick_cont">
        <Profile v-if="isShow.profile" @child="getProfileEmitData" ref="quickProfile"></Profile>
        <Link v-if="isShow.link" :user-dmd-info="userDmdInfo" :is-login="isLogin" ref="quickLink"></Link>
        <Menu v-if="isShow.menu" :country-digit-code="countryDigitCode2" @child="getMenuEmitData" ref="quickMenu"></Menu>
        <PromotionSchedule v-if="isShow.promotionschedule" ref="PromotionSchedule"></PromotionSchedule>
        <Schedule v-if="isShow.schedule" ref="quickSchedule"></Schedule>
        <RecentSchedule v-if="isShow.recentSchedule" @child="getRecentScheduleEmitData" ref="quickRecentSchedule"></RecentSchedule>
        <Working v-if="isShow.working" :country-digit-code="countryDigitCode3" ref="quickWorking"></Working>
        <QuickCalcIssue
          v-show="isShow.calcIssue"
          ref="quickCalcIssue"
          @isLoadOk="isLoadOk"
          :country-digit-code="countryDigitCode3"
          @child="getCalcIssueData"
        ></QuickCalcIssue>
        <ManagerInfo v-if="isShow.managerInfo" :is-login="isLogin" :country-digit-code="countryDigitCode2" ref="quickManagerInfo"></ManagerInfo>
        <a href="javascript:void(0)" @click="quickClose()" class="quick_close"><span>close</span></a>
      </div>
      <!-- quick_box // -->
    </div>
    <!-- quick_wrap // -->
    <div id="quick_pop1">
      <win-layer-pop>
        <component
          v-if="customComponent1"
          :is="customComponent1"
          :parent-info="parentInfo"
          @child="getPopEmitData"
          @close="closePopWrap"
          :select-data.sync="selectData"
          :profile-list="profileList"
        />
      </win-layer-pop>
    </div>
    <div id="quick_pop2">
      <win-layer-pop>
        <component
          v-if="customComponent2"
          :is="customComponent2"
          :parent-info="parentInfo"
          @close="closePopWrap2"
        />
      </win-layer-pop>
    </div>
  </div>
</template>

<script>
import store from '@/store/index'
import { rootComputed, rootMethods } from '@/store/helpers'
import Profile from '@/components/layout/quick/Profile'
import Link from '@/components/layout/quick/Link'
import Menu from '@/components/layout/quick/Menu'
import ManagerInfo from '@/components/layout/quick/ManagerInfo'
import PromotionSchedule from '@/components/layout/quick/PromotionSchedule'
import Schedule from '@/components/layout/quick/Schedule'
import RecentSchedule from '@/components/layout/quick/RecentSchedule'
import Working from '@/components/layout/quick/Working'
import QuickCalcIssue from '@/components/layout/quick/QuickCalcIssue'
import ProfileApi from '@/api/rest/user/profile'
import SelectProfile from '@/components/layout/SelectProfile'
import WinLayerPop from '@/pages/trans/popup/WinLayerPop'
import WinLayerPopWrap from '@/pages/trans/popup/WinLayerPopWrap'
import ScheduleComparePopType01 from '@/components/schedule/ScheduleComparePopType01'
import VesselScheduleInfoPop from '@/pages/schedule/popup/VesselScheduleInfoPop'/* 선박정보 및 스케줄 팝업 */
import VtlAccPop from '@/pages/settle/popup/VtlAccPop'
import BlDoPaymentPop from '@/pages/trans/popup/BlDoPaymentPop'
import api from '@/api/services/auth'
import countryCode from '@/plugins/country.json'
import CalcIssue from '@/api/rest/settle/calcIssue'
export default {
  name: 'Quick',
  components: {
    Profile,
    Link,
    Menu,
    ManagerInfo,
    PromotionSchedule,
    Schedule,
    RecentSchedule,
    Working,
    QuickCalcIssue,
    SelectProfile,
    WinLayerPop,
    WinLayerPopWrap,
    ScheduleComparePopType01,
    VesselScheduleInfoPop,
    VtlAccPop,
    BlDoPaymentPop
  },
  data: function () {
    return {
      compList: ['profile', 'link', 'menu', 'promotionschedule', 'schedule', 'recentSchedule', 'working', 'calcIssue', 'managerInfo'],
      isShow: {
        profile: true,
        link: true,
        menu: true,
        promotionschedule: false,
        schedule: false,
        recentSchedule: false,
        working: false,
        calcIssue: false,
        managerInfo: true
      },
      isQuickCalcIssueLoad: false,
      profileList: [],
      customComponent1: null,
      customComponent2: null,
      updateProfileIdx: null,
      selectData: [],
      parentInfo: {},
      loginResp: null,
      beforeLoginResp: null,
      linkTotalCnt: 0,
      countryCode: countryCode,
      countryDigitCode2: '',
      countryDigitCode3: '',
      userDmdInfo: {
        etDmdCatCd: '',
        imDmdCatCd: ''
      }
    }
  },
  computed: {
    isLogin () {
      return !this.isEmpty(store.state.auth.jwt)
    },
    path () {
      return this.$route.path
    },
    personalizedFlag () {
      return store.state.personalizedFlag
    },
    isOpenPersonalized () {
      return store.state.isOpenPersonalized
    },
    profile () {
      return store.state.auth.selectedProfile
    },
    ...rootComputed
  },
  watch: {
    path: function () {
      if (this.path !== '/main') {
        this.$store.commit('updateIsOpenPersonalized', false)
      }
    },
    profile: {
      deep: true,
      handler () {
        setTimeout(() => {
          this.getData()
        }, 500)
      }
    },
    personalizedFlag () {
      this.getData()
    },
    isOpenPersonalized () {
      if (this.isOpenPersonalized) {
        this.quickOpen()
      } else {
        this.quickClose()
      }
    }
  },
  created () {
  },
  mounted () {
    let type = navigator.appName
    let lang = ''
    // let userAgentData = navigator.userAgentData.brands

    // console.log('navigator >>> ', navigator)
    // console.log('navigator userAgentData brands>>> ', userAgentData)
    if (this.isLogin) {
      // GA dataLayer 추가
      window.dataLayer[0].ga_user_id = this.auth.userId
      window.dataLayer[0].is_login = 'Y'
      window.dataLayer[0].id = this.auth.orgUserId
      window.dataLayer[0].user_profile = this.auth.userName
      /*
      window.dataLayer.push({
        event: 'login',
        ga_user_id: this.auth.userId,
        user_contact_region: this.auth.userCtrCd,
        user_member_type: this.auth.cstCatCd,
        user_pic: this.profile.eiCatCd,
        is_login: 'Y',
        user_profile: this.auth.userName,
        id: this.auth.orgUserId
      })
      */
      this.getUserDmd()
      this.getUnsolved()
      if (!this.isEmpty(this.auth.userCtrCd)) {
        this.countryDigitCode2 = countryCode[this.auth.userCtrCd].digitCode2
        this.countryDigitCode3 = countryCode[this.auth.userCtrCd].digitCode3
      } else {
        this.countryDigitCode2 = countryCode[lang.split('-')[1]].digitCode2
        this.countryDigitCode3 = countryCode[lang.split('-')[1]].digitCode3
      }
      this.quickOpen()
    } else { // 브라우저 조건에 따라 판단(엣지, ...)
      // GA dataLayer 추가
      window.dataLayer[0].ga_user_id = ''
      window.dataLayer[0].is_login = 'N'
      window.dataLayer[0].id = ''
      window.dataLayer[0].user_profile = ''

      if (type === 'Netscape') {
        lang = navigator.language
      } else {
        lang = navigator.userLanguage
      }
      // this.countryDigitCode2 = countryCode[lang.split('-')[1]].digitCode2 // 접속국가 'KR' , 아니면 serviceLang으로 판단 -> 메뉴 보여주기
      this.countryDigitCode2 = this.$ekmtcCommon.isEmpty(localStorage.getItem('service_ctrcd')) ? 'KR' : localStorage.getItem('service_ctrcd')
      //this.countryDigitCode3 = countryCode[lang.split('-')[1]].digitCode3 // 접속국가 KOR
      this.countryDigitCode3 = this.$ekmtcCommon.isEmpty(localStorage.getItem('service_ctrcd')) ? 'KR' : localStorage.getItem('service_ctrcd')
    }
  },
  methods: {
    ...rootMethods,
    getUserDmd () {
      CalcIssue.getUserDmd({}).then(async response => {
        this.userDmdInfo = response.data
      }).catch(e => {
        this.userDmdInfo = {
          etDmdCatCd: '',
          imDmdCatCd: ''
        }
      })
    },
    isLoadOk (obj) {
      //
      this.isQuickCalcIssueLoad = true
    },
    getPopEmitData (data) {
      this.updateProfileIdx = null
      let isOkNotZero = true

      switch (data.type) {
        case 'close':
          this.$ekmtcCommon.layerClose('#quick_pop1 > .popup_dim')
          break
        case 'select':
          this.updateProfileIdx = data.payload.idx

          // 약관동의 항목 체크
          if (this.profileList[this.updateProfileIdx].mdtyPsnlInfCltnCnstYn !== 'Y') {
            // 선택한 프로필의 필수동의 체크
            isOkNotZero = false
          }

          // console.log('@@@@@ this.profileList[this.updateProfileIdx] ', this.profileList[this.updateProfileIdx])

          if (!isOkNotZero) {
              this.$ekmtcCommon.layerClose('#quick_pop1 > .popup_dim')

              this.$router.push(
              {
                name: 'login-approval',
                params: {
                  checkView: 'changeProfile',
                  profile: this.profileList[this.updateProfileIdx]
                }
              })
              .catch(() => {})
          } else {
            this.changeProfileAlert()
          }
          break
        case 'openPop':
          this.parentInfo = data.payload.parentInfo
          this.customComponent2 = data.payload.compNm
          this.$ekmtcCommon.layerOpen('#quick_pop2> .popup_dim')
          break
        case 'addProfilePage':
          this.$ekmtcCommon.layerClose('#quick_pop1 > .popup_dim')

          store.commit('auth/updateIsEmptyProfileLogin', true)

          setTimeout(() => {
              this.$router.push(
                {
                  name: 'profile',
                  params: {
                      checkView: 'beforeProfile',
                      profile: 'N'
                  }
                })
          }, 100)

          break
      }
    },
    getRecentScheduleEmitData (data) {
      switch (data.type) {
        case 'openPop':
        // console.log(data)
          this.selectData = data.payload.selectData
          this.customComponent1 = data.payload.compNm
          this.$ekmtcCommon.layerOpen('#quick_pop1 > .popup_dim')
          break
      }
    },
    getMenuEmitData (data) {
      switch (data.type) {
        case 'openPop':
          this.customComponent1 = data.payload.compNm
          this.parentInfo = data.payload.parentInfo
          this.$ekmtcCommon.layerOpen('#quick_pop1 > .popup_dim')
          break
      }
    },
    getProfileEmitData (data) {
      switch (data.type) {
        case 'openSelectProfilePop':
          this.getProfileList()
          break
      }
    },
    getCalcIssueData (data) {
      switch (data.type) {
        case 'openPop':
          this.customComponent1 = data.payload.compNm
          this.$ekmtcCommon.layerOpen('#quick_pop1 > .popup_dim')
          break
      }
    },
    getProfileList () {
      ProfileApi.getProfileList(sessionStorage.getItem('loginId'), this.auth.userCtrCd).then(response => {
        this.profileList = this.parseProfileList(response.data)
        this.customComponent1 = SelectProfile
        this.$ekmtcCommon.layerOpen('#quick_pop1 > .popup_dim')
      }).catch(e => {
        console.log(e)
      })
    },
    parseProfileList (arr) {
      let res = []
      let myIdx = null
      if (!this.isEmpty(this.selectedProfile)) {
        for (let i = 0; i < arr.length; i++) {
          if ((arr[i].picNo === this.selectedProfile.picNo) && (arr[i].cstCatCd === this.selectedProfile.cstCatCd)) {
            arr[i].isMe = true
            res.push(arr[i])
            myIdx = i
            break
          }
        }
      }

      if (myIdx != null) {
        arr.splice(myIdx, 1)
      }
      for (let i = 0; i < arr.length; i++) {
        res.push(arr[i])
      }
      for (let i = 0; i < res.length; i++) {
        if (this.isEmpty(res[i].picPoto)) {
          res[i].picPotoString = null
        } else {
          let image = new Image()
          image.src = 'data:image/png;base64,' + res[i].picPoto
          res[i].picPotoString = image.src
        }
      }
      return this.orderByUseYnProfileList(res)
    },
    orderByUseYnProfileList (arr) {
      let res = []
      let noUseArr = []
      for (let p of arr) {
        if (p.useYn === 'N') {
          noUseArr.push(p)
        } else {
          res.push(p)
        }
      }

      for (let noUse of noUseArr) {
        res.push(noUse)
      }
      return res
    },
    changeProfileAlert () {
      let obj = {
            onConfirm: this.chagneProfile,
            customCloseBtnClass: 'button blue lg',
            customCloseBtnText: this.$i18n.t('msg.ONIM010P070.008'), // 취소
            customConfirmBtnText: this.$i18n.t('msg.MYIN050T010.056'), // 확인
            useConfirmBtn: true,
            customConfirmBtnClass: 'button blue lg',
            message: this.$i18n.t('msg.MYIN050T010.076'), // 프로필을 교체하시겠습니까? // WMH
            type: 'info'
          }
          this.$ekmtcCommon.alert(obj)
    },
    chagneProfile () {
        let req = {
          userId: this.profileList[this.updateProfileIdx].userId,
          jwt: this.$store.state.auth.loginInfo.jwt
        }

        // GA dataLayer 추가
        window.dataLayer[0].user_profile = this.profileList[this.updateProfileIdx].picNm
        window.dataLayer[0].ga_user_id = this.profileList[this.updateProfileIdx].userId
        window.dataLayer[0].id = this.profileList[this.updateProfileIdx].preUserId
        window.dataLayer[0].is_login = 'Y'

        window.dataLayer.push({
          event: 'profile_change',
          ga_user_id: this.profileList[this.updateProfileIdx].userId,
          user_contact_region: this.profileList[this.updateProfileIdx].userCtrCd,
          user_member_type: this.profileList[this.updateProfileIdx].cstCatCd,
          user_pic: this.profileList[this.updateProfileIdx].eiCatCd,
          is_login: 'Y',
          user_profile: this.profileList[this.updateProfileIdx].picNm,
          id: this.profileList[this.updateProfileIdx].preUserId
        })

        // [[ pre user is 셋팅
        // profile pre_user_id 로 변경
        //console.log('idx>>>>>>>>>>>>>>>>>>>>>>>>>>>>', this.profileList)
        //console.log('this.profileList[idx].preUserId', this.profileList[this.updateProfileIdx])
        //console.log('sessionStorage.getItem(loginId)', sessionStorage.getItem('loginId'))
        if (!this.isEmpty(this.profileList[this.updateProfileIdx].preUserId) && sessionStorage.getItem('loginId') !== this.profileList[this.updateProfileIdx].preUserId) {
          sessionStorage.setItem('loginId', this.profileList[this.updateProfileIdx].preUserId)
          // [[ 로그인 추가 프로필 변경 시 user_id가 변경되면 재 로그인...
          let upperForm = {
              userId: this.profileList[this.updateProfileIdx].preUserId,
              userPwd: this.profileList[this.updateProfileIdx].preUserId
            }
          api.logInfo(upperForm).then(loginResp => {
            let respCode = loginResp.headers.respcode
            // console.log('loginResp==========>', loginResp)
            if (respCode === 'C0000') {
              this.beforeLoginResp = loginResp.data
              let cstCd = null
              if (upperForm.userId.indexOf('EKMTC_') === -1) {
                sessionStorage.setItem('cstCd', loginResp.data.cstCd)
              } else {
                sessionStorage.setItem('cstCd', 'KMT013')
              }
              sessionStorage.setItem('loginId', upperForm.userId)
              sessionStorage.setItem('logCatCd', loginResp.data.logCatCd)

              req.jwt = this.beforeLoginResp.data.jwt
            }
          }).catch(e => {
            console.log(e)
          })
        }
        // 로그인 추가 ]]
        api.profileLogin(req).then(resp => {
          if (this.isEmpty(this.beforeLoginResp)) {
            this.beforeLoginResp = JSON.parse(JSON.stringify(this.$store.state.auth.loginInfo))
          }
          this.loginResp = resp
          this.saveLoginData(this.updateProfileIdx)
        }).catch(e => {
          console.log(e)
        })

        // if (!this.isEmpty(this.updateProfileIdx)) {
        //   store.commit('auth/selectedProfile', this.profileList[this.updateProfileIdx])
        //   this.$store.commit('updatePersonalizedFlag')
        //   this.$ekmtcCommon.layerClose('#quick_pop1 > .popup_dim')
        // } else {
        //   let obj = {
        //     alertType: 'simple',
        //     customCloseBtnClass: 'button blue lg simplert-test1',
        //     customCloseBtnText: '확인',
        //     useIcon: false,
        //     customClass: 'simple_alert',
        //     message: '프로필 교체에 실패했습니다.',
        //     type: 'info'
        //   }
        //   this.$ekmtcCommon.alert(obj)
        //   this.$ekmtcCommon.layerClose('#quick_pop1 > .popup_dim')
        // }
    },
    saveLoginData (profileIdx) {
      let respCode = this.loginResp.headers.respcode
      let respMsg = this.$i18n.t(`js.auth.${respCode}`)
      if (respCode === 'C0000') {
        this.saveLogin({
          user: this.loginResp.data,
          jwt: this.loginResp.data.jwt,
          autoLogin: true,
          profile: this.profileList[profileIdx],
          loginInfo: this.beforeLoginResp
        }).then(r => {
          this.$store.commit('updatePersonalizedFlag')
          this.$ekmtcCommon.layerClose('#quick_pop1 > .popup_dim')

          // let isOkNotZero = true

          // 약관동의 항목 체크
          // if (this.profileList[profileIdx].mdtyPsnlInfCltnCnstYn === 'N') {
          //   // 선택한 프로필의 필수동의 체크
          //   isOkNotZero = false
          // }

          // if (!isOkNotZero) {
          //     this.$router.push(
          //     {
          //       name: 'login-approval',
          //       params: {
          //         checkView: 'toProfile',
          //         profile: this.profileList[profileIdx]
          //       }
          //     })
          //     .catch(() => {})
          // }
        }).catch(r => {
          let obj = {
            alertType: 'simple',
            customCloseBtnClass: 'button blue lg simplert-test1',
            customCloseBtnText: this.$i18n.t('msg.MYIN050T010.056'), // 확인
            useIcon: false,
            customClass: 'simple_alert',
            message: this.$i18n.t('msg.MYIN050T010.077'), // 프로필 교체에 실패했습니다. WMH
            type: 'info'
          }
          this.$ekmtcCommon.alert(obj)
          this.$ekmtcCommon.layerClose('#quick_pop1 > .popup_dim')
        })
      } else {
          let obj = {
            alertType: 'simple',
            customCloseBtnClass: 'button blue lg simplert-test1',
            customCloseBtnText: this.$i18n.t('msg.MYIN050T010.056'), // 확인
            useIcon: false,
            customClass: 'simple_alert',
            message: '프로필 교체에 실패했습니다.', // WMH
            type: 'info'
          }
          this.$ekmtcCommon.alert(obj)
          this.$ekmtcCommon.layerClose('#quick_pop1 > .popup_dim')
      }
    },
    quickOpen: function () {
      // 단위테스트 > (Web)MAIN_FOOTER_개인화_UTIL > 2번 비고 요청사항으로 추가하였습니다.
      this.$el.addEventListener('click', this.stopProp)
      document.body.addEventListener('click', this.quickClose)

      $('.quick_box').animate({ right: 0 })
      this.getIsShow()
    },
    quickClose: function () {
      // 단위테스트 > (Web)MAIN_FOOTER_개인화_UTIL > 2번 비고 요청사항으로 추가하였습니다.
      this.$el.removeEventListener('click', this.stopProp)
      document.body.removeEventListener('click', this.quickClose)

      $('.quick_box').animate({ right: -362 })
    },
    getUnsolved () {
      if (this.isShow.link) {
        if (this.isLogin && this.auth.staffFlag === 'N') {
          this.$refs.quickLink.getNewUnsolvedList()
        }
      }
    },
    async getData () {
      if (this.isShow.profile) {
        //this.$refs.quickProfile.getMileage()
      }
      // if (this.isShow.link) {
      //   if (this.isLogin) {
      //     // this.$refs.quickLink.getMyRequestList()
      //     //this.$refs.quickLink.getScheduleChangeInfo()
      //     this.$refs.quickLink.getNewUnsolvedList()
      //   }
      // }
      if (this.isShow.menu) {
        //
      }
      if (this.isShow.promotionschedule) {
        setTimeout(() => {
          this.$refs.PromotionSchedule.getPromotionSchedules()
        }, 0)
      }
      if (this.isShow.schedule) {
        setTimeout(() => {
           this.$refs.quickSchedule.getSuggestionSchedules()
        }, 100)
      }
      if (this.isShow.recentSchedule) {
        setTimeout(() => {
          this.$refs.quickRecentSchedule.init()
        }, 300)
      }
      if (this.isShow.working) {
        setTimeout(() => {
           this.$refs.quickWorking.getWorkingCnt()
        }, 100)
      }
      if (this.isShow.calcIssue) {
        // if (this.isQuickCalcIssueLoad) {
        //   this.$refs.quickCalcIssue.searchData()
        //   this.$refs.quickCalcIssue.getVrAccount()
        // }
        this.$refs.quickCalcIssue.searchData()
        this.$refs.quickCalcIssue.getVrAccount()
        this.$refs.quickCalcIssue.findNotIssuedCount()
      }
      if (this.isShow.managerInfo) {
        setTimeout(() => {
          this.$refs.quickManagerInfo.getManagerInfo()
        }, 500)
      }
    },
    getIsShow () {
      let parentPath = this.getParentPath()
      for (let comp of this.compList) {
        switch (comp) {
          case 'profile':
            this.isShow.profile = true
            break
          case 'link':
            this.isShow.link = true
            break
          case 'menu':
            this.isShow.menu = !this.isLogin
            break
          case 'promotionschedule':
            this.isShow.promotionschedule = this.isLogin //(this.auth.userCtrCd === 'KR')
            break
          case 'schedule':
            this.isShow.schedule = this.isLogin
            break
          case 'recentSchedule':
            this.isShow.recentSchedule = (parentPath === 'schedule') && this.isLogin
            break
          case 'working':
            this.isShow.working = (parentPath === 'working') && this.isLogin
            break
          case 'calcIssue':
            this.isShow.calcIssue = (parentPath === 'calcIssue') && this.isLogin
            break
          case 'managerInfo':
            this.isShow.managerInfo = true
            break
        }
      }
      this.getData()
    },
    closePopWrap () {
      this.customComponent1 = null
      $('#quick_pop1 > .popup_dim').fadeOut()
    },
    closePopWrap2 () {
      this.customComponent2 = null
      $('#quick_pop2 > .popup_dim').fadeOut()
    },
    getParentPath () {
      let splitPath = this.$route.path.split('/')
      return splitPath[1]
    },
    isEmpty (value) {
      if (value === '' || value === null || value === undefined || (value != null && typeof value === 'object' && !Object.keys(value).length)) {
          return true
      } else {
          return false
      }
    },
    // 단위테스트 > (Web)MAIN_FOOTER_개인화_UTIL > 2번 비고 요청사항으로 추가하였습니다.
    stopProp (e) {
      e.stopPropagation()
    }
  }
}
</script>

<style scoped>
</style>
